import './App.css';

function App() {
  return (
    <div className="App">
     <p>Welcome to altmannbence.hu!</p>
    </div>
  );
}

export default App;
